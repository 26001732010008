export function getTitle(profile) {
  return (
    profile.c_answersCardOverride?.title ||
    profile.c_answersCardTitle ||
    profile.c_title ||
    profile.name || 
    profile.c_heroSection?.headline
  );
}

export function getImage(profile, defaultImage) {
  let image = 
    profile.c_answersCardOverride?.image ||
    profile.c_answersCardImage ||
    profile.photoGallery?.[0] ||
    profile.c_featuredPhoto;
  
  if (image) {
    return Formatter.image(image).url;
  } else if (!profile.type.includes('ce_feature')) {
    return defaultImage;
  }
}

export function getSquareImage(profile) {
  return (
    profile.c_answersCardOverride?.squareImage || 
    profile.c_answersCardSquareImage ||
    profile.c_answersCardImage || 
    profile.logo ||
    profile.headshot ||
    profile.c_heroSection?.photo
  )
}

export function getRichDescription(profile) {
  return (
    profile.c_answersCardOverride?.description ||
    profile.c_answersCardRichSubhead ||
    profile.richTextDescription ||
    profile.description || " "
  );
}

export function getUrl(profile) {
  var url = profile.c_answersCardOverride?.url ||
    profile.c_answersCardURL ||
    profile.website ||
    profile.landingPageUrl ||
    profile.c_pagesURL ||
    profile.websiteUrl?.url ||
    profile.c_generatedUrl ||
    profile.d_link;
  // don't return a url that's still in staging preview
  if (url && url.includes('/staging/')) {
    url = null;
  }

  return url;
}