/**
 * Highlights snippets of the provided fieldValue according to the matched substrings.
 * Each match will be wrapped in <mark> tags.
 * 
 * @param {string} fieldValue The plain, un-highlighted text.
 * @param {Array<Object>} matchedSubstrings The list of matched substrings to
 *                                          highlight.
 */

/*
 * 
 * Updating the formatter to support dates without a time 
 * Source: https://hitchhikers.yext.com/community/t/bigdate-formatter-update-to-date-instead-of-datetime/3190/2 */

import { format, utcToZonedTime } from "date-fns-tz";
import enUS from "date-fns/locale/en-US";

export function formatTimezone(timezone) {
  if (!timezone) {
    return "";
  }

  const formattedTimezone = format(utcToZonedTime(new Date(), timezone), "zzz", {
    timeZone: timezone,
    locale: enUS,
  });

  return formattedTimezone;
}

export function bigDate(profile, keyPath = 'time.start') {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return null;
  }

  let updateDateString;
  if (dateString.includes('T')) {
    updateDateString = dateString;
  } else {
    updateDateString = dateString + "T00:00";
  }

  const date = betterTime(updateDateString); //pass this updatedDateString instead of just the dateString
  const locale = _getDocumentLocale();
  const time = date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return {
    day: date.getDate(),
    month: date.toLocaleString(locale, { month: 'long' }),
    year: date.getFullYear(),
    time: time
  };
}

//adding the following to support the above override of the bigDate formatter
export function _getProfileFieldAtKeyPath(profile, keypath) {
  const paths = keypath.split('.');

  if (!paths.length) {
    console.error('invalid key path', keypath);
    return null;
  }

  return paths.reduce((haystack, needleKey) => {
    if (!haystack) {
      console.log('haystack was null or undefined', haystack, needleKey, idx);
      return null;
    }
    const needle = haystack[needleKey];
    if (!needle) {
      console.log('could not find ' + needleKey, haystack);
      return null;
    }

    return needle;
  }, profile);
}

export function _getDocumentLocale() {
  return document.documentElement.lang.replace('_', '-');
}

export function betterTime(stamp) {
  const offset = new Date(stamp).getTimezoneOffset() / 60;
  const offsetStr = (offset < 0 ? '+0' : '-0') + Math.abs(offset) + ':00';
  return new Date(stamp + offsetStr);
}